// Модальные окна
.dialog {
    color           : var(--color-black);
    background-color: var(--color-white);
    max-width       : 100%;

    &--small {
        width: 425px;
    }

    &--medium {
        width: 620px;
    }

    &--large {
        width: 1120px;
    }
}
// Кнопка
.btn {
    display        : flex;
    align-items    : center;
    justify-content: center;
    position       : relative;
    user-select    : none;
    overflow       : hidden;
    cursor         : pointer;

    font: {
        size  : var(--font-16);
        family: var(--font-base);
        weight: 600;
    }

    transition: {
        duration       : 0.2s;
        timing-function: ease;
    }

    @include getBtn('primary', 'white');
    @include getBtn('second', 'black');
    @include getBtn('green', 'white');
    @include getBtn('white', 'black');
    @include getBtn('grey', 'black');

    &--default {
        height       : 48px;
        border-radius: var(--size-3);

        padding: {
            left : var(--size-5);
            right: var(--size-5);
        }
    }

    &--fill {
        color           : var(--color-sec);
        background-color: var(--color-main);
    }

    &--light {
        color           : var(--color-main);
        background-color: var(--color-light);
    }

    &--border {
        border: {
            width: 2px;
            style: solid;
            color: var(--color-main);
        }
    }

    &:not(&--fill, &--light) {
        color           : var(--color-main);
        background-color: var(--color-none);
    }

    &:not(&--border) {
        border: none;
    }

    &:focus {
        box-shadow: 0 0 0 var(--size-1) var(--focus);
    }

    &:active {
        box-shadow: inset 0 var(--size-1) var(--size-1) var(--color-black-30);
        transform : translateY(var(--size-1));
    }

    &:disabled {
        pointer-events: none;
        opacity       : 0.5;
    }

    @media(hover) {
        &--fill:hover {
            background-color: var(--hover-fill);
        }

        :is(&, &--light, &--border):not(&--fill):hover {
            background-color: var(--hover-light);
        }
    }
}
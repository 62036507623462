// Переменные цветов
$colors: ('none': rgba(0, 0, 0, 0),
    'primary': #9653CF,
    'second': #A7F0E7,
    'black': #171717,
    'white': #ffffff,
    'grey': #EEEEEE,
    'gray': #b2b2b2,
    'purple': #7C3FAE,
    'dark': #303030,
    'red': #DE2E63,
    'green': #5ECBBE);

// Переменные шрифтов
$font-family: ('base': 'Inter');

// Переменные позиционирования
$position: 'static',
'sticky',
'relative',
'absolute',
'fixed';

// Переменные выравнивания текста
$text-align: 'left',
'center',
'right',
'justify';

// Переменные отображения элемента
$visibility: 'visible',
'hidden';

// Переменные отображения содержимого
$overflow: 'visible',
'hidden',
'scroll',
'auto';

// Переменные для указателя
$pointer-events: 'all',
'none';

// Переменные анимации
$transition: 'ease',
'ease-in',
'ease-out',
'ease-in-out',
'linear';

// Переменные разрешений экрана
$laptop: 1200px;
$tablet: 768px;
$mobile: 576px;

// Создание переменных в CSS
:root {

    @each $key,
    $value in $colors {
        @include getColors($value, $key);
    }

    @for $i from 5 through 25 {
        --font-#{calc(2 * $i)}: #{calc(2 * $i / 16) + rem};
    }

    @for $i from 0 through 10 {
        --size-#{$i}: #{calc(4 * $i) + px};
    }

    @for $i from 0 to 10 {
        --z-#{$i}: #{calc(100 * $i)};
    }

    @each $key,
    $value in $font-family {
        --font-#{$key}: '#{$value}';
    }
}

// Выделение
::selection {
    color           : var(--color-white);
    background-color: var(--color-primary);
}
// Цвета
@each $key,
$value in $colors {
    .color-#{$key} {
        color: var(--color-#{$key});
    }
}

// Фоновые цвета
@each $key,
$value in $colors {
    .bg-#{$key} {
        background-color: var(--color-#{$key});
    }
}

// Прозрачность
@for $i from 0 through 10 {
    .fade-#{calc($i * 10)} {
        opacity: #{calc($i / 10)};
    }
}
// Объединение
.cover {
    width    : 100%;
    max-width: 100%;
    display  : flex;
    position : relative;

    &__img {
        position      : absolute;
        pointer-events: none;

        &--arrow {
            bottom: var(--size-4);
            right : var(--size-4);
            transform: rotate(90deg);
        }
    }
}

// Поле
.input {
    flex            : 1;
    width           : 100%;
    color           : var(--color-black);
    background-color: var(--color-white);
    padding         : var(--size-2) var(--size-4);
    height          : 48px;
    user-select     : none;

    font: {
        size  : var(--font-16);
        family: var(--font-base);
    }

    border: {
        width : 1px;
        style : solid;
        color : var(--color-grey);
        radius: var(--size-2);
    }

    transition: {
        duration       : 0.2s;
        timing-function: ease;
    }

    &:not(&--error):focus {
        border-color: var(--color-gray);
    }

    &:is(&--error) {
        border-color: var(--color-red);
    }

    &::placeholder {
        color: var(--color-gray);
    }

    &:disabled {
        pointer-events: none;
        opacity       : 0.5;
    }
}

// Ошибка
.error {
    display         : flex;
    align-items     : center;
    justify-content : center;
    text-align      : center;
    position        : absolute;
    left            : 0;
    right           : 0;
    bottom          : calc(-1 * var(--size-4));
    height          : var(--size-4);
    font-size       : var(--font-12);
    color           : var(--color-white);
    background-color: var(--color-red);
    border-radius   : var(--size-2);
}
// Скролл
html {
    scrollbar: {
        width: thin;
        color: var(--color-purple) var(--color-grey);
    }

    &::-webkit-scrollbar {
        width: var(--size-3);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-grey);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-purple);
    }
}